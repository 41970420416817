import React, {useEffect, useState} from "react";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import LandingPageWidget from "../components/LandingPageWidget";
import * as apiService from "../backend/apiService";
import {useNavigate} from "react-router-dom";
import {getTokenData} from "../backend/storage";
import { backendEndpoint, frontendEndpoint } from '../backend/axiosInstance';
import * as Store from "../backend/storage";

import styles from "../style/pages/Home.module.scss"
import ActivityIndicator from "../components/ActivityIndicator";

const Home = () => {
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const token = getTokenData();
        if (token !== undefined) apiService.fetchUserCities().then(res => {
            if (res.current) {
                apiService.fetchArtistRecommendations(res.current.id)
                    .then(() => setLoggedIn(true))
                    .catch(error => error);
            }
        }).catch(error => error);
    }, []);

    // Header Animation Configs
    const headers = ['music.', 'artists.', 'events.', 'venues.'];
    const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState(styles.fadeIn);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFadeClass(styles.fadeOut);
            setTimeout(() => {
                setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headers.length);
                setFadeClass(styles.fadeIn);
            }, 250); // Match this with the fade-out duration in CSS
        }, 2800); // Change text every 2.5 seconds

        return () => clearInterval(intervalId);
    }, [headers.length]);

    // Access Spotify Authorization
    const randomString = require("randomstring")
    const [spotifyButtonActive, setSpotifyButtonActive] = useState<boolean>(false);

    const spotifyLogin = async () => {
        setSpotifyButtonActive(true);
        let random = randomString.generate({ length: 32, charset: "alphabetic" });
        await Store.storeSpotifySecret(random);
        const inputString = random + '_localify-auth';

        // Use the Web Crypto API to generate a SHA-256 hash
        const encoder = new TextEncoder();
        const data = encoder.encode(inputString);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(byte => String.fromCharCode(byte)).join('');
        const base64Hash = btoa(sha256Hash);

        // Make the base64-encoded hash URL-friendly by replacing characters
        const urlFriendlyHash = base64Hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

        // Replace the following line with your actual Spotify URL
        window.location.href = `${backendEndpoint}v1/auth/spotify/login?redirect=${frontendEndpoint}spotify-login&state=${urlFriendlyHash}=`
    }

    return (
        <div className={`"App-body" ${styles['gradient-background']}`}>
            <PageHeader type={HeaderTypes.LANDING} loggedIn={loggedIn}/>
            <div className={styles['main-container']}>
                <div className={styles['header-section']}>
                    <div className={styles['header-text']}>
                        <h1>Local <span
                            className={`${styles.animatedHeader} ${fadeClass}`}>{headers[currentHeaderIndex]}
                        </span></h1>
                        <p>Discover local artists and events in <span>your town</span> based
                            on <span>your music taste</span>.</p>
                        <button onClick={() => navigate(`/login`)}>Get your recommendations</button>
                    </div>
                </div>

                <div className={`${styles['local-musicians']} ${styles['main-margin']}`}>
                    <div className={styles['local-musicians-text']}>
                        <p>Support</p>
                        <p><span>local</span></p>
                        <p><span>musicians</span></p>
                        <div className={styles['local-musicians-subtext']}>
                            There are talented local musicians all around us.
                            <br/>
                            <br />Localify allows you to rediscover all the music you love
                            from artists close to home.
                        </div>
                    </div>
                    <LandingPageWidget/>
                </div>
                <div className={`${styles['experience-sound']} ${styles['main-margin']}`}>
                    <div className={styles['experience-sound-pics']}>
                        <img title={'Photo by Sam Moghadam Khamseh on Unsplash'} alt={'A small music venue performance'} src={"https://localify-cdn.s3.amazonaws.com/assets/web_assets/local-venue.jpg"}/>
                        <img className={styles['location-pin-icon']} alt={'A pink location pin icon'} src={'https://localify-cdn.s3.amazonaws.com/assets/web_assets/pink-location-pin.svg'}/>
                    </div>
                    <div className={styles['local-musicians-text']}>
                        <div>
                            <p>Experience</p>
                            <p><span>local venues</span></p>
                        </div>
                        <div className={styles['local-musicians-subtext']}>
                            {/*Go out and explore and have fun! <br /><br />*/}
                            Localify highlights local events and venues, bringing you even closer to local favorites and
                            strengthening the local economy by helping small businesses thrive!
                        </div>
                    </div>
                </div>
                <div className={`${styles['linking']} ${styles['main-margin']}`}>
                    <h2>Tailored <span>just for you</span></h2>
                    <div className={styles['linking-content']}>
                        <div className={styles['spotify-link']}>
                            <p>Link to your <span>Spotify</span> and get recommendations based on your
                                current listening</p>
                            {/*TODO add actual spotify link*/}

                            {!spotifyButtonActive ?
                                <button className={styles['alt-button']} onClick={() => spotifyLogin()}>
                                    Continue with Spotify
                                </button>
                                :
                                <button className={styles['alt-button']} onClick={() => spotifyLogin()}>
                                    <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                                </button>
                            }
                        </div>
                        <div className={styles['no-link']}>
                            <p>Sign in without linking and simply tell us your faves</p>
                            <button className={styles['alt-button']} onClick={() => navigate(`/login`)}>
                                Continue without Linking</button>
                        </div>
                    </div>
                </div>
                <div className={`${styles['benefits']} ${styles['main-margin']}`}>
                    <div className={styles['benefits-contents']}>
                        <div>
                            <h2>Why use <span>Localify?</span></h2>
                        </div>
                    </div>
                    <div className={styles['benefits-contents2']}>
                        <div className={styles['benefits-item']}>
                            <img alt={'Pink heart icon'}
                                 src={"https://localify-cdn.s3.amazonaws.com/assets/web_assets/pink-personalized-heart.svg"}/>
                            <p>Build up your local music community and economy.</p>
                        </div>
                        <div className={styles['benefits-item']}>
                            <img alt={'Pink music note icon'}
                                 src={"https://localify-cdn.s3.amazonaws.com/assets/web_assets/pink-hidden-talent.svg"}/>
                            <p>Discover hidden local talent and expand your palate!</p>
                        </div>
                        <div className={styles['benefits-item']}>
                            <img alt={'Pink calendar icon'}
                                 src={"https://localify-cdn.s3.amazonaws.com/assets/web_assets/pink-events-in-your-city.svg"}/>
                            <p>Find upcoming events and venues right next door.</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles['crowdsourcing']} ${styles['main-margin']}`}>
                    <h2>Localify <span>needs your knowledge!</span></h2>
                    <p>We rely on <span>crowdsourcing</span> to keep our data on artist origins and hometowns accurate
                        and
                        up-to-date.</p>
                    <img alt={''}/>
                    <p>Know where an artist is from? <span>Share it with us!</span></p>
                    <p>Your contributions help expand our database, making it easier for others to discover artists and
                        support
                        their work.</p>
                </div>
                <div className={`${styles['our-story']} ${styles['main-margin']}`}>
                    <img alt={'A collection of the logos of the National Science Foundation, Ithaca College and Cornell ' +
                        'University'} src={"https://localify-cdn.s3.amazonaws.com/assets/web_assets/supporter-logos.png"}/>
                    <div>
                        <h2>A Student-Created Innovation with National Science Foundation Backing</h2>
                        <p>Localify, a pioneering platform, emerged from the collaboration of dedicated students at
                            Ithaca
                            College and Cornell University. With the National Science Foundation's substantial support,
                            Localify stands as a testament to the intersection of academic pursuit and practical
                            application,
                            offering a cost-free experience devoid of advertisements. This initiative not only enhances
                            the
                            accessibility of local music but also contributes to the fields of machine learning,
                            recommender
                            systems, software engineering, and human-computer interaction research.</p>
                    </div>
                </div>
                <PageFooter/>
            </div>
        </div>
    )
};

export default Home;
