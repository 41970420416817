import React from 'react';
import PageHeader, { HeaderTypes } from "../components/PageHeader";
import styles from "../style/pages/AboutUs.module.scss";
import {toSlug} from "../utils/StringFormatting";
import teamMembers from "../assets/TeamMembers.json";

const AboutUs = () => {

    return (
        <div className="App-body">
            <PageHeader type={HeaderTypes.LANDING}/>
            <div className={styles["main-container"]}>
                <h1 className={styles["title"]}>About the Team</h1>
                <h6 className={styles["subtitle"]}>
                    Funded by the NSF, Localify is a collaboration between Ithaca College
                    and Cornell University, inspired by Ithaca's vibrant local music scene.
                    It helps people worldwide discover and enjoy their local music scenes.
                    Led by Ithaca College undergraduates and supervised by Professor Doug
                    Turnbull, with Cornell's support. Read on to meet our team, learn about
                    their work, and discover their favorite local artists!
                </h6>
                {teamMembers.subjects.map((subject, index) => (
                    <>
                        <h3 className={styles["subject-title"]}>{subject.name}</h3>
                        <div className={styles["team-grid"]}>
                            {subject.members.map((teamMember, index) => (
                                <div
                                    key={index}
                                    className={styles["member-card"]}
                                >
                                    <img
                                        alt={"Image of " + teamMember.name}
                                        src={teamMember.image}
                                        className={styles["member-image"]}
                                    />
                                    <div className={styles["member-info"]}>
                                        <div className={styles["basic-info"]}>
                                            <h5 className={styles["member-name"]}>{teamMember.name}</h5>
                                            <h6 className={styles["grad-year"]}>{teamMember.yearOfGraduation}</h6>
                                        </div>
                                        <h6 className={styles["member-role"]}>{teamMember.role}</h6>
                                        <h6
                                            className={styles["favorite-band"]}
                                            onClick={() =>
                                                window.open(window.location.origin + `/artist/${teamMember.favoriteLocalBandId}/${toSlug(teamMember.favoriteLocalBand)}`, "_blank")
                                            }
                                        >{teamMember.favoriteLocalBand} - {teamMember.favoriteBandOrigin}</h6>
                                        <p className={styles["bio"]}>{teamMember.bio}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
};

export default AboutUs;
